import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import CTABanner from "../components/Common/CTABanner"
import Footer from "../components/App/Footer"
import SEO from "../components/App/SEO"

const Privacy = () => {
  return (
    <Layout>
      <SEO
        title="Privacy Policy | Oxygen - Home Loans Made Simple"
        description="This Privacy Policy was last updated on 8th March 2022."
      />
      <Navbar />
      <PageBanner
        // pageTitle=""
        pageSubTitle="This Privacy Policy was last updated on 8th March 2022."
      />
      <section className="privacy-policy-area pb-100">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12">
              <div className="privacy-policy-content">
                {/*<p><i>This Privacy Policy was last updated on 8th March 2022.</i></p>*/}
                <h3 className="pt-lg-3 pt-md-0">1. Introduction</h3>
                <p>
                  Oxygen Broking Services Pty Ltd (ACN 103 177 377) and its
                  related bodies corporate (including but not limited to Oxygen
                  Lending Services Pty Ltd, Oxygen Capital Group Pty Ltd) and
                  their franchisees, agents and brokers (
                  <b>Oxygen, we, our, us</b>) respect the importance of
                  protecting your privacy. We comply with our obligations under
                  the Privacy Act 1998 (Cth) (<b>Privacy Act</b>), including the
                  Australian Privacy Principles (<b>APPs</b>) concerning
                  personal information of individuals.
                </p>
                <p>
                  This Privacy Policy describes how Oxygen collects, handles,
                  uses and discloses your personal information. By providing us
                  with your personal information, you consent to us using and
                  disclosing it for the purposes set out in this Privacy Policy.
                  We will update this Privacy Policy when our information
                  handling practices change, or when required. Any revised
                  Privacy Policy will take effect when it is published on our
                  website.
                </p>
                <h3>2. Our functions and activities</h3>
                <p>
                  We collect, hold, use and disclose your personal information
                  so that we can perform our functions and activities and
                  provide best possible customer service. Our collection, use
                  and disclosure of your personal information will depend on our
                  relationship with you, the circumstances of collection and the
                  type of products and service you request from us. We may
                  collect, hold, use and disclose your personal information for
                  the following purposes:
                </p>
                <ul>
                  <li>
                    To provide and administer our products and services,
                    including market properties for vendors and landlords,
                    facilitate transactions for sales and leasing, assess and
                    originate your application for finance or mortgage insurance
                    and manage that finance, property concierge services, verify
                    your identity, conduct credit checks and process payments if
                    applicable;
                  </li>
                  <li>
                    To respond to your submissions and enquiries about our
                    products and services and property listings;
                  </li>
                  <li>
                    To provide customer support, including conducting,
                    investigating and responding to enquiries, surveys,
                    feedback, comments and complaints;
                  </li>
                  <li>
                    To distribute our newsletters and other communications
                    either alone or with the assistance of third party service
                    providers on information about us, our conferences, training
                    and events, our property listings and our related products
                    and services;
                  </li>
                  <li>
                    To plan, market, host and facilitate our conferences,
                    training sessions and events;
                  </li>
                  <li>
                    To inform and conduct marketing activities including to
                    promote our products and services;
                  </li>
                  <li>
                    To conduct research about your interests and attitudes
                    towards our products and services to improve our products
                    and services, marketing activities and customer experience;
                  </li>
                  <li>
                    To assess the performance of our websites and improve the
                    operation of the websites;
                  </li>
                  <li>
                    To perform data analyses and generate customer insights
                    which may include combining personal information from
                    reputable data sources and public sources of information;
                  </li>
                  <li>
                    To manage and carry out our business and operational
                    functions, including HR management, business decisions and
                    assessing your suitability for appointment as a franchisee
                    or contractor agent or broker;
                  </li>
                  <li>
                    To register any shareholding you may have in Oxygen Limited,
                    contact you in relation to that shareholding and maintain a
                    register of shareholders under the Corporations Act 2001
                    (Cth); and
                  </li>
                  <li>
                    To maintain records and comply with our legal obligations.
                  </li>
                </ul>
                <h3>3. Information we collect</h3>
                <p className="non-bold">
                  Personal information is any information about you, from which
                  you can be identified. We may collect your name, address,
                  postcode, email address, date of birth, gender, employment,
                  telephone number, property details, payment details and proof
                  of identity such as your driver licence or passport number. We
                  may collect additional personal information from you from time
                  to time.
                </p>
                <p className="non-bold">
                  Personal information we collect about you may also include
                  credit-related personal information. Credit-related
                  information is information used and disclosed to assess your
                  creditworthiness, assess your application for finance and
                  manage that finance. The types of credit-related personal
                  information that we collect about you may include your credit
                  liability information, repayment history information, the type
                  and amount of finance you are applying for, court proceedings,
                  insolvency actions and credit worthiness.
                </p>
                <p className="non-bold">
                  We generally do not collect sensitive information about you,
                  unless you provide it to us voluntarily. For example, when you
                  interact with one of our agents or brokers, you may provide
                  sensitive information such as racial or ethnic origin, sexual
                  orientation, health information, religious or philosophical
                  beliefs or political opinion and membership or affiliation
                  information. You consent to us collecting sensitive
                  information which you provide to us voluntarily. If we need to
                  collect any sensitive information for a specific purpose, we
                  will ask for your consent.
                </p>
                <p className="non-bold">
                  If you are an employee or prospective employee, we may need to
                  collect sensitive information about you and you consent to us
                  collecting this information. For example, personnel records
                  may include sensitive information such as place of birth,
                  racial or ethnic origin, criminal record, tax file number,
                  disabilities and relationship information. Recruitment records
                  may include employment history and experience, referees and
                  other employment related information.
                </p>
                <p className="non-bold">
                  If you provide us sensitive information, we will treat it as
                  personal information and handle it in accordance with this
                  Privacy Policy.
                </p>
                <p className="non-bold">
                  If you are a shareholder or prospective investor, we (or our
                  agent) will collect certain details about you for registration
                  purposes, such as your name, address, number of shares held,
                  tax file number and bank account details.
                </p>
                <p className="non-bold">
                  You don’t have to give us all the information we request.
                  However, if you do not provide us with some or all of the
                  personal information required, we may not be able to provide
                  you with the products and services or information you request,
                  to the requested standard or at all, and you may also miss out
                  on receiving valuable information about us and our products
                  and services.
                </p>

                <h3>4. How we collect information</h3>
                <p>
                  Where we can, we will collect information directly from you.
                  We collect personal information when you:
                </p>
                <ul>
                  <li>
                    Request us to provide our products and services or
                    information about our products and services, including
                    sales, leasing and mortgage brokering;
                  </li>
                  <li>
                    Contact us to make an enquiry about our listed properties or
                    our products and services, including online, over the phone
                    or in person;
                  </li>
                  <li>
                    Subscribe to communications from us such as updates,
                    publications or newsletters;
                  </li>
                  <li>
                    Tell us about your preferences when making an enquiry or
                    using our products and services;
                  </li>
                  <li>
                    Contact us to provide feedback, comments or suggestions on
                    our functions and activities or complete one of our surveys;
                  </li>
                  <li>
                    Interact or engage with us through our websites or social
                    media platforms;
                  </li>
                  <li>
                    Register, attend or present at one of our conferences,
                    training sessions and events;
                  </li>
                  <li>
                    Attend property inspections or visit our offices, including
                    via manual sign-in and security surveillance of our offices;
                  </li>
                  <li>
                    Apply for a job with us or become an employee, or become a
                    supplier or contractor that provide a product or service to
                    Oxygen;
                  </li>
                  <li>Purchase shares in Oxygen Limited; or</li>
                  <li>
                    Otherwise interact with us or disclose your personal
                    information to us.
                  </li>
                </ul>
                <p className="non-bold">
                  As well as collecting information directly from you, there may
                  be occasions when we may collect information about you from
                  other people or organisations, including from companies in the
                  Oxygen group. We may combine this information with information
                  we already have about you to help us understand trends,
                  attributes, behaviour and preferences. Where we receive
                  information about you indirectly from third parties, we
                  require that the third party has collected and shared that
                  information in accordance with the Privacy Act.
                </p>
                <p className="non-bold">
                  Third parties we collect information about you from may
                  include:
                </p>
                <ul>
                  <li>
                    Agents and brokers who are contractors of Oxygen that
                    provide our products and services on our behalf;
                  </li>
                  <li>
                    Oxygen franchisees that operate under the Oxygen brand;
                  </li>
                  <li>
                    Credit reporting bodies and providers, law enforcement
                    agencies and government agencies to verify your identity or
                    assess your credit worthiness;
                  </li>
                  <li>
                    Reputable data sources and publicly available sources of
                    information;
                  </li>
                  <li>
                    Your representatives (lawyers, mortgage broker, accountants
                    and financial advisors);
                  </li>
                  <li>
                    Your nominated referee or employer when you apply to rent a
                    property or for finance;
                  </li>
                  <li>
                    Third parties that provide marketing leads, marketing and
                    data analysis services to us;
                  </li>
                  <li>Third parties that conduct our surveys for us;</li>
                  <li>
                    Speaker management companies if you present at one of our
                    conferences, training sessions or events;
                  </li>
                  <li>
                    Your share broker when you purchase shares in Oxygen
                    Limited; and
                  </li>
                  <li>
                    If you are a prospective employee, your nominated referee,
                    law enforcement agencies for police checks or intermediaries
                    such as recruitment agents and personnel providers.
                  </li>
                </ul>
                <h3>5. Cookies and how we use them</h3>
                <p className="non-bold">
                  We also collect general information about you when you use our
                  websites. This might include your geo-location, IP address,
                  the browser and operating system you are using, and details of
                  websites that IP address has come from, the pages accessed on
                  our website and the next website visited.
                </p>
                <p className="non-bold">
                  We also use cookies, which are small data files placed on your
                  computer that allows our website to “remember you” when you
                  return to our website. We do this to monitor visitor traffic
                  information and actions on our website, maintain, secure and
                  improve our websites and enhance your experience when using
                  our website. This information is aggregated and anonymous, and
                  will not be used to personally identify you. If you want to
                  prevent cookies being used, you can change your browser
                  settings to disable cookies. However, you may not be able to
                  access all or parts of our websites, or you may experience
                  reduced functionality when accessing certain services.
                </p>
                <h3>6. Disclosure of personal information</h3>
                <p className="non-bold">
                  Oxygen may disclose your personal information to our officers,
                  employees, agents, brokers and contractors. Oxygen may also
                  disclose your personal information between companies in the
                  Oxygen group and to Oxygen franchisees that operate under the
                  Oxygen brands, and each of their officers, employees, agents,
                  brokers and contractors.
                </p>
                <p className="non-bold">
                  We may disclose your personal information to third parties in
                  connection with our functions and activities, including:
                </p>
                <ul>
                  <li>
                    To the other parties in the transaction for sale and
                    leasing, including developers, vendors, landlords,
                    purchasers and tenants, and their authorised representative;
                  </li>
                  <li>
                    To guarantors, other mortgage intermediaries, lenders,
                    financial institutions, insurers, valuers and credit
                    reporting bodies and providers;
                  </li>
                  <li>
                    To your authorised representatives (lawyers, mortgage
                    brokers, accountants and financial advisors);
                  </li>
                  <li>
                    To regulatory bodies, government agencies and law
                    enforcement bodies in any jurisdiction;
                  </li>
                  <li>
                    To the relevant government agency for verification of
                    attendance at conferences and training sessions for
                    continuing professional development requirements;
                  </li>
                  <li>To debt collectors and utility companies;</li>
                  <li>
                    If you are an employee or contractor, to third party sales
                    consultants when on secondment or to hotels, airlines and
                    other providers when you travel;
                  </li>
                  <li>
                    If you are a shareholder, our share register is available
                    for inspection by the public as required under the
                    Corporations Act 2001 (Cth). The information collected in
                    connection with your shareholding may also be disclosed to
                    regulatory bodies such as the Australian Taxation Office;
                    and
                  </li>
                  <li>When we may be required by law from time to time.</li>
                </ul>
                <p className="non-bold">
                  We may also disclose personal information to our suppliers and
                  service providers that provide products and services to us in
                  connection with our functions and activities, including third
                  parties who:
                </p>
                <ul>
                  <li>Deliver marketing and digital marketing services;</li>
                  <li>Mailing, courier and print service providers;</li>
                  <li>Provide event registration and management services;</li>
                  <li>Conduct data analysis and data matching services;</li>
                  <li>Conduct market research, surveys and analysis;</li>
                  <li>Provide a broker database;</li>
                  <li>Host our servers and websites;</li>
                  <li>Provide IT services;</li>
                  <li>Data processing, storage and back-up; or</li>
                  <li>Process payments;</li>
                  <li>Provide recruitment consultant services;</li>
                  <li>
                    Manage HR information such as payroll and superannuation; or
                  </li>
                  <li>Manage our share registry.</li>
                </ul>
                <p className="non-bold">
                  Some of the third parties we disclose your personal
                  information to may have servers located overseas in various
                  countries, including USA, Europe and Japan.
                </p>
                <p className="non-bold">
                  We require that all third parties, to whom we disclose
                  personal information or who may have access to personal
                  information, have appropriate controls to protect your
                  personal information in a manner that is consistent with our
                  Privacy Policy, including in relation to security and
                  confidentiality. They must only use your personal information
                  for authorised purposes.
                </p>
                <h3>7. Direct Marketing</h3>
                <p className="non-bold">
                  Oxygen may, from time to time, send direct marketing
                  communications to you about us and our products and services,
                  our property listings and other material that we consider you
                  would find interesting or useful. If you do not wish to
                  receive such direct marketing communications, you can always
                  opt out. If you are receiving email communications from us,
                  there will be a mechanism to opt out contained in each of
                  those emails. To stop receiving other communications from us,
                  you can contact us via any of the channels listed below.
                </p>
                <p className="non-bold">
                  If you choose to opt out of all direct marketing
                  communications, please note that Oxygen may still contact you
                  for other reasonable purposes, including information that
                  Oxygen is legally required to send, notifications of changes
                  to Oxygen products and services or policies and information
                  regarding the use, rights, benefits or obligations of
                  customers of our products and services.
                </p>
                <h3>8. How we hold information and keep it secure</h3>
                <p className="non-bold">
                  Oxygen holds your personal information both electronically, on
                  our own servers and those of our service providers, and in
                  hard copy at our secured offices.
                </p>
                <p className="non-bold">
                  Oxygen takes the security of your personal information
                  seriously and we implement a range of technical,
                  administrative, personnel and physical measures to safeguard
                  your personal information against loss, interference and
                  unauthorised access, modification and disclosure, and misuse,
                  including using electronic and physical access restrictions to
                  files containing personal information and ensuring encryption
                  of personal information sent and received.
                </p>
                <h3>9. Access and correction of information about you</h3>
                <p className="non-bold">
                  It is important to us that the information we hold about you
                  is up-to-date, accurate and complete, and we will try to
                  confirm your details through our communications with you and
                  promptly add updated or new personal information to existing
                  records when we are advised. If any of your details change,
                  please notify us as soon as you can. If you believe we are
                  holding information about you that is inaccurate, incomplete,
                  irrelevant or misleading, you can ask us to correct it, or
                  delete it altogether.
                </p>
                <p className="non-bold">
                  If you would like to access your personal information, or ask
                  us to update, correct or delete it, you can do so by
                  contacting us in writing and verifying your identity. We will
                  do our best to respond to your request within 30 days.
                </p>
                <p className="non-bold">
                  We will only refuse access in exceptional circumstances, and
                  if this is the case, we will advise you of our reasons for
                  doing so.
                </p>
                <p className="non-bold">
                  We may charge a fee for searching for, and providing access
                  to, your information on a per request basis.
                </p>
                <h3>10. Privacy complaints and further information</h3>
                <p className="non-bold">
                  We take your complaints seriously and will attempt to resolve
                  your issue quickly and fairly.
                </p>
                <p className="non-bold">
                  If you believe that Oxygen has breached the APPs, or you have
                  a complaint about how we handle your personal information:
                </p>
                <ul>
                  <li>
                    You may make a complaint addressed to the Oxygen Privacy
                    Officer. The complaint must identify you and be in writing.
                  </li>
                  <li>
                    The Oxygen Privacy Officer will investigate your complaint
                    and will endeavour to provide a response setting out
                    Oxygen’s decision in writing within 30 days of receipt of
                    your complaint.
                  </li>
                  <li>
                    If Oxygen is unable to satisfactorily resolve your concerns
                    you can contact the office of the Australian Information
                    Commissioner on their website{" "}
                    <a href="https://www.oaic.gov.au">www.oaic.gov.au</a> or you
                    may refer the complaint to Oxygen’s external dispute
                    resolution provider:
                  </li>
                </ul>
                {/* <p>
                  On or before 31 October 2018, please refer the complaint to
                  the Credit and Investments Ombudsmen (CIO):
                </p>
                <ul>
                  <li>
                    Phone:{" "}
                    <span className="privacy-contact-info">1800 138 422</span>
                  </li>
                  <li>
                    Email: <a href="mailto:info@cio.com.au">info@cio.com.au</a>
                  </li>
                  <li>
                    Online: <a href="https://www.cio.org.au">www.cio.org.au</a>
                  </li>
                </ul> */}
                <p className="non-bold">
                  Australian Financial Complaints Authority (AFCA):
                </p>
                <ul>
                  <li>
                    Phone:{" "}
                    <span className="privacy-contact-info">1800 931 678</span>
                  </li>
                  <li>
                    Email:{" "}
                    <a href="mailto:info@afca.org.au">info@afca.org.au</a>
                  </li>
                  <li>
                    Online:{" "}
                    <a href="https://www.afca.org.au">www.afca.org.au</a>
                  </li>
                </ul>
                <p className="non-bold">
                  This is a free service which provides you with an independent
                  mechanism to resolve certain complaints.
                </p>
                <h3>11. Our contact details</h3>
                <p className="non-bold">
                  If you require any further information or have any questions
                  about this Privacy Policy or if you wish to access or correct
                  your personal information or make a complaint about our
                  handling of that information, please contact the Oxygen
                  Privacy Officer as follows:
                </p>
                <ul>
                  <li>
                    Email:{" "}
                    <a href="mailto:privacy@oxygen.com.au">
                      privacy@oxygen.com.au
                    </a>
                  </li>
                  <li>
                    Telephone:
                    <span className="privacy-contact-info"> 1300 855 699</span>
                  </li>
                  {/* <li>
                    Fax:{" "}
                    <span className="privacy-contact-info">(02) 9386 3344</span>
                  </li> */}
                  <li>
                    Post:
                    <span className="privacy-contact-info">
                      {" "}
                      The Privacy Officer,
                      <br /> Oxygen Home Loans
                      <br /> Level 12, 92 Pitt St
                      <br /> Sydney
                      <br /> NSW 2000
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
      <CTABanner />
      <Footer />
    </Layout>
  )
}

export default Privacy
